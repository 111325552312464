svg {
  &:not(:root) {
    overflow: visible;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background: #a7a7a7c9;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #696969c9;
}
